import { CounselingStatus } from "../types";

export const counselingStatuses: CounselingStatus[] = [
  {
    id: "WELCOME",
    value: 5,
    label: "counselingStatus.welcome",
    display: false,
  },
  {
    id: "ENTITLEMENTS_PREFERENCES",
    value: 17,
    label: "counselingStatus.entitlements",
    display: true,
  },
  {
    id: "ENTITLEMENTS_FORMS",
    value: 17,
    label: "counselingStatus.entitlements",
    display: false,
  },
  {
    id: "MOVE_TYPE",
    value: 33,
    label: "counselingStatus.move_type",
    display: true,
  },
  {
    id: "ADDRESSES_ORIGIN",
    value: 50,
    label: "counselingStatus.addresses",
    display: true,
  },
  {
    id: "ADDRESSES_DESTINATION",
    value: 50,
    label: "counselingStatus.addresses",
    display: false,
  },
  {
    id: "ADDRESSES_EXTRA_PICKUP",
    value: 50,
    label: "counselingStatus.addresses",
    display: false,
  },
  {
    id: "ADDRESSES_EXTRA_DELIVERY",
    value: 50,
    label: "counselingStatus.addresses",
    display: false,
  },
  {
    id: "STORAGE_IN_TRANSIT",
    value: 50,
    label: "counselingStatus.addresses",
    display: false,
  },
  {
    id: "MOVE_DATES",
    value: 67,
    label: "counselingStatus.dates",
    display: true,
  },
  {
    id: "SURVEY_SCHEDULE_TYPE",
    value: 84,
    label: "counselingStatus.survey",
    display: true,
  },
  {
    id: "SURVEY_SCHEDULE_TIME",
    value: 84,
    label: "counselingStatus.survey",
    display: false,
  },
  {
    id: "COMPLETE",
    value: 100,
    label: "counselingStatus.complete",
    display: true,
  },
  {
    id: "UNKNOWN",
    value: 0,
    label: "counselingStatus.unknown",
    display: false,
  },
];
