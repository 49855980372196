import { FormattedMessage } from "services";
import { CounselingStatus } from "types";
import { useOrder } from "store";
import { counselingStatuses } from "constant/counselingStatuses";
import { NavPill } from "../ui/NavPill";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { PageWrapper } from "../PageWrapper";
const CounselingStatusWrapper = styled("div")({
  borderBottom: "1px solid #e5e5e5",
  height: "100%",
  display: "flex",
  alignContent: "center",
  justifyContent: "center",
  paddingBottom: "33px",
  marginTop: "33px",
  backgroundColor: "#fff",
  maxWidth: "none",
});

const StatusWrapper = styled.div({
  textAlign: "center",
  marginTop: "4px",
  "@media (max-width: 768px)": {
    display: "none",
  },
});

const StatusUl = styled.ul({
  listStyle: "none",
  width: "100%",
  padding: 0,
  margin: 0,
  display: "grid",
  gridTemplateColumns: "repeat(auto-fit, minmax(.25rem, 1fr))",
  gridColumnGap: "0.5rem",
});

const StatusLi = styled.li({
  display: "flex",
  flexDirection: "column",
  listStyle: "none",
});

export function CounselingStatusBar() {
  const { moveType: moveType, hasEntitlementsCounseling } = useOrder();

  const activeCounselingStatus = counselingStatuses.find(
    (status) => status.id === moveType.counselingStatus
  );

  if (!activeCounselingStatus) {
    return <></>;
  }

  const displayPill = (
    status: CounselingStatus,
    activeStatus: CounselingStatus
  ) => {
    const pillStatus =
      status.value < activeStatus.value
        ? "complete"
        : status.value === activeStatus.value
        ? "active"
        : "inactive";

    return <NavPill status={pillStatus} ariaLabel={status.label} />;
  };

  const statusList = counselingStatuses.map((item) => {
    if (item.display) {
      if (item.id.startsWith("ENTITLEMENTS") && !hasEntitlementsCounseling) {
        return null;
      }

      return (
        <StatusLi key={item.value} data-testid={item.id}>
          <div>{displayPill(item, activeCounselingStatus)}</div>
          <StatusWrapper>
            <Typography variant="xsHeading">
              <FormattedMessage id={item.label} />
            </Typography>
          </StatusWrapper>
        </StatusLi>
      );
    }
    return null;
  });

  return (
    <CounselingStatusWrapper data-testid="counseling-status-bar">
      <PageWrapper noOffset>
        <StatusUl>{statusList}</StatusUl>
      </PageWrapper>
    </CounselingStatusWrapper>
  );
}
