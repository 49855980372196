import { formatNumber } from "tools";
import { FormattedMessage, useIntl } from "services";
import { Card, FootnoteMarker } from "../ui";
import homeIcon from "./assets/home.svg";
import styled from "@emotion/styled";
import { Customer, Entitlement } from "models";
import { Box, Divider as MuiDivider, Typography } from "@mui/material";
import { ReactNode } from "react";

const ContainerLineItem = styled.div({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const ContentHeader = (props: { children: ReactNode }) => (
  <Typography variant="sHeading" {...props} />
);

const EntitlementValue = (props: { children: ReactNode }) => (
  <Typography
    variant="lHeading"
    color="#0069a0"
    textAlign={"right"}
    {...props}
  />
);

const Divider = styled(MuiDivider)`
  margin-bottom: ${() => `1.25rem`};
  padding-top: ${() => `1.25rem`};
  border-color: ${() => `#b3b3b3`};
`;

interface EntitlementsCardProps {
  customer: Customer;
  entitlement: Entitlement;
}

export function EntitlementsCard({
  customer,
  entitlement,
}: EntitlementsCardProps) {
  const { formatMessage } = useIntl();

  const calculateTotalEntitlementWeight = () => {
    return formatNumber(
      entitlement?.totalWeight +
        entitlement?.proGearWeight +
        entitlement?.proGearWeightSpouse +
        entitlement?.gunSafeWeight
    );
  };

  const hasDependents = entitlement.dependentsAuthorized;

  return (
    <Card
      title={<FormattedMessage id="entitlements.entitlementsCard.title" />}
      headerIcon={<img src={homeIcon.src} />}
      aria-label={formatMessage({ id: "ariaLabels.entitlementDetails" })}
      variant="large"
    >
      <Box
        mb="32px"
        aria-label={formatMessage({ id: "ariaLabels.summaryText" })}
      >
        <Typography variant="mBody">
          <FormattedMessage
            id="entitlements.entitlementsCard.message"
            values={{
              dependencyStatus: (
                <strong>
                  {hasDependents ? (
                    <FormattedMessage id="entitlements.entitlementsCard.withDependents" />
                  ) : (
                    <FormattedMessage id="entitlements.entitlementsCard.withoutDependents" />
                  )}
                </strong>
              ),
              paygrade: (
                <strong>
                  {entitlement.payGrade}{" "}
                  <FormattedMessage id="entitlements.entitlementsCard.paygrade" />
                </strong>
              ),
              moveType: (
                <strong>
                  <FormattedMessage id="entitlements.entitlementsCard.conusMove" />
                </strong>
              ),
            }}
          />
        </Typography>
      </Box>
      <div aria-label={formatMessage({ id: "ariaLabels.householdGoods" })}>
        <ContainerLineItem>
          <ContentHeader>
            <FormattedMessage id="entitlements.entitlementsCard.householdGoods" />
          </ContentHeader>
          <EntitlementValue
            aria-label={formatMessage({
              id: "ariaLabels.householdGoodsAmount",
            })}
          >
            {formatNumber(entitlement?.totalWeight)} {customer.weightUnits}
          </EntitlementValue>
        </ContainerLineItem>
      </div>
      {/* The Unaccompanied Baggage weight will be hardcoded to 0 lbs for go live */}
      <div
        aria-label={formatMessage({ id: "ariaLabels.unaccompaniedBaggage" })}
      >
        <ContainerLineItem>
          <ContentHeader>
            <FormattedMessage id="entitlements.entitlementsCard.unaccompaniedBaggage" />
          </ContentHeader>
          <EntitlementValue
            aria-label={formatMessage({
              id: "ariaLabels.unaccompaniedBaggageAmount",
            })}
          >
            0 {customer.weightUnits}
          </EntitlementValue>
        </ContainerLineItem>
      </div>
      <div aria-label={formatMessage({ id: "ariaLabels.workEntitlements" })}>
        <ContainerLineItem>
          <ContentHeader>
            <FormattedMessage id="entitlements.entitlementsCard.memberProGear" />
            <FootnoteMarker />
          </ContentHeader>

          <EntitlementValue
            aria-label={formatMessage({
              id: "ariaLabels.workEntitlements.personal",
            })}
          >
            {entitlement?.proGearWeight > 0 ? "+" : ""}
            {formatNumber(entitlement?.proGearWeight)} {customer.weightUnits}
          </EntitlementValue>
        </ContainerLineItem>
        <ContainerLineItem>
          <ContentHeader>
            <FormattedMessage id="entitlements.entitlementsCard.spouseProGear" />
            <FootnoteMarker />
          </ContentHeader>

          <EntitlementValue
            aria-label={formatMessage({
              id: "ariaLabels.workEntitlements.spouse",
            })}
          >
            {entitlement?.proGearWeightSpouse > 0 ? "+" : ""}
            {formatNumber(entitlement?.proGearWeightSpouse)}{" "}
            {customer.weightUnits}
          </EntitlementValue>
        </ContainerLineItem>
        {entitlement.userPreferenceGunSafe ? (
          <ContainerLineItem data-testid="gunSafeRow">
            <ContentHeader>
              <FormattedMessage id="entitlements.entitlementsCard.emptyGunSafe" />
              <FootnoteMarker />
              <FootnoteMarker />
            </ContentHeader>

            <EntitlementValue
              aria-label={formatMessage({
                id: "ariaLabels.workEntitlements.safe",
              })}
            >
              +{formatNumber(entitlement?.gunSafeWeight)} {customer.weightUnits}
            </EntitlementValue>
          </ContainerLineItem>
        ) : null}
      </div>
      <Divider />
      {/* The 3 PPM fields and GSA Rates are hardcoded to $0 for go live */}
      <ContainerLineItem>
        <ContentHeader>
          <FormattedMessage id="entitlements.entitlementsCard.totalGovernment" />
        </ContentHeader>
        <EntitlementValue>$0</EntitlementValue>
      </ContainerLineItem>
      <ContainerLineItem>
        <ContentHeader>
          <FormattedMessage id="entitlements.entitlementsCard.totalAdvance" />
        </ContentHeader>
        <EntitlementValue>$0</EntitlementValue>
      </ContainerLineItem>
      <ContainerLineItem>
        <ContentHeader>
          <FormattedMessage id="entitlements.entitlementsCard.totalIncentive" />
        </ContentHeader>
        <EntitlementValue>$0</EntitlementValue>
      </ContainerLineItem>
      <ContainerLineItem>
        <ContentHeader>
          <FormattedMessage id="entitlements.entitlementsCard.commutedRate" />
        </ContentHeader>
        <EntitlementValue>$0</EntitlementValue>
      </ContainerLineItem>
      <Box sx={{ marginTop: "24px" }}>
        <Typography variant="xsBody">
          <FootnoteMarker />
          <FormattedMessage id="entitlements.footnote.pbp" />
        </Typography>
      </Box>
      {entitlement.userPreferenceGunSafe ? (
        <Box sx={{ marginTop: "8px" }}>
          <Typography variant="xsBody">
            <FootnoteMarker />
            <FootnoteMarker />
            <FormattedMessage id="entitlements.footnote.gunSafe" />
          </Typography>
        </Box>
      ) : null}
    </Card>
  );
}
